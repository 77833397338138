import React, { useState } from 'react';
import './having-trouble.styles.scss';

const HavingTroubleComponent = () => {

  const [expand, setExpand] = useState(false);

  const toggleExpansion = () => {
    setExpand(!expand);
  };

  return (
    <div className={"having-trouble"} onClick={toggleExpansion}>
      <span className={'customLink'}>Having trouble retrieving your PIN?</span>
      {
        expand ?
          <div>
            Please contact, or return to the store where the product was bought.
          </div> : null
      }
    </div>
  );
};

export default HavingTroubleComponent;
