import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import ClientPage from './pages/client/client.page';
// import Header from './components/header/header.component';

import './App.css';
// import FAQPage from './pages/faq/faq.page';

const App: React.FC = () => {
  return (
    <div className="App">
      {/*<Header />*/}
      <Switch>
        <Route exact path='/' component={ ClientPage } />
        {/*<Route exact path='/faq' component={ FAQPage } />*/}
        <Route render={() => (<Redirect to='/' />)} />
      </Switch>
    </div>
  );
};

export default App;
