import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './response.styles.scss';
import HavingTroubleComponent from '../having-trouble/having-trouble.component';

interface responseProps {
  responseCode?: string;
  secondaryResponseCode?: string;
  status?: string;
  pan?: string;
  pin?: string;
  receiptFields?: string[];
  termsAndConditions?: string;
  error?: {
    message?: string,
    description?: string,
    status?: number
  }
}

enum requestStatusEnum {
  failed,
  pending,
  successful
}

const Response = ({ status, pin, receiptFields, termsAndConditions }: responseProps) => {

  const setStatus = (status: string) => {
    if (['ACTIVE', 'Activated', 'Redeemed'].includes(status)) {
      return requestStatusEnum.successful;
    }

    if (status.match(/Pending Activate/ig)) {
      return requestStatusEnum.pending;
    }

    return requestStatusEnum.failed;
  };

  const [requestStatus] = useState(status ? setStatus(status) : requestStatusEnum.failed);


  return (
    <div className={'response-component'}>

      {
        requestStatus === requestStatusEnum.successful ?
          (
            <div className={'requestSuccess'}>
              <h2>Success</h2>
              {
                pin ?
                  <div className={'pin'}>
                    <p>You can use the PIN below to activate your purchased product.</p>
                    <h2>{pin}</h2>
                  </div>
                  : null
              }

              {
                receiptFields && receiptFields.length > 0 ?
                  <div>
                    <h4>Redemption instructions</h4>

                    <ol>
                      { receiptFields.map((item) => (<li> { item } </li>))}
                    </ol>
                  </div>
                  :
                  null
              }

              {
                termsAndConditions ?
                  <div>
                    <h4>Terms and conditions</h4>

                    <p>{termsAndConditions}</p>
                  </div>
                  :
                  null
              }

              <div>
                <h4>Thank you</h4>

                <p>Thank you for using this service to retrieve your PIN.</p>
              </div>
            </div>
          )
          : null
      }

      {
        requestStatus === requestStatusEnum.pending ?
          (
            <div className={'requestPending'}>
              <h1>Pending</h1>
              <p>Busy processing request, please check back in a few minutes. This can take up to 24 hours.</p>

              <HavingTroubleComponent />
            </div>
          )
          : null
      }

      {
        requestStatus === requestStatusEnum.failed ?
          (
            <div className={'requestFailed'}>
              <h1>Retrieval failed</h1>

              <p>We are unable to retrieve a pin for the provided customer reference</p>
              <HavingTroubleComponent />
            </div>
          )
          : null
      }


      <Link to="/newPin">
        <input
          className='btn btn-outline-primary mr-1 mb-2 flex-fill btn-lg btn-block'
          type="button"
          value="Retrieve another PIN"/>
      </Link>

      {/*<pre>
        status: {status}
        responseCode: {responseCode}
        secondaryResponseCode: {secondaryResponseCode}
        pin: {pin}
        receiptFields: {receiptFields}
      </pre>*/}
    </div>
  )
};

export default Response;
