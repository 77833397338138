import React, { useState } from 'react';
import axios from 'axios';

import Response from '../../components/response/response.component';
import './client.styles.scss';
import tillSlip from '../../assets/till-slip.png';
import InputMask from 'react-input-mask';
import HavingTroubleComponent from '../../components/having-trouble/having-trouble.component';

const ClientPage = () => {
  const [customerRef, setCustomerRef] = useState({ value: '', touched: false, dirty: false, pristine: true, valid: false, error: '' });
  const [formError, setFormError] = useState<any>(null);
  const [submitting, setSubmitting] = useState(false);

  const [error, setError] = useState<{
      message?: string,
      description?: string,
      status?: number
    }>({});

  const [response, setResponse] = useState<{
    responseCode?: string,
    secondaryResponseCode?: string,
    status?: string,
    pan?: string,
    pin?: string,
    receiptFields?: string[],
    error?: {
      message?: string,
      description?: string,
      status?: number
    }
    }>({});

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setFormError(null);
    setError({});

    switch (name) {
      case 'customerReference': {
        // console.log(`Typing (pristine: false), value: ${value}`);
        setCustomerRef({
          value: value,
          pristine: false,
          touched: customerRef.touched,
          dirty: customerRef.dirty,
          ...validateUUID(value)
        });
        break;
      }
    }
  };

  const handleBlur = () => {
    // console.log('Blurring (dirty: true)');
    setCustomerRef({  ...customerRef, dirty: true, ...validateUUID(customerRef.value) });
  };

  const handleFocus = () => {
    // console.log('Focussing (touched: true)');
    setCustomerRef({  ...customerRef, touched: true });
  };

  const validateUUID = (value: string) => {
    if (!value || value.match(/^_/)) {
      // console.log('Validating: Invalid length');
      return { valid: false, error: 'Customer reference is required' };
    }

    if (value.length !== 36 || value.match(/_/)) {
      // console.log('Validating: Invalid length');
      return { valid: false, error: 'Customer reference should be 36 characters long.' };
    }

    if (!value.match(/^[\da-f]{8}-([\da-f]{4}-){3}[\da-f]{12}$/)) {
      // console.log('Validating: Invalid match');
      return { valid: false, error: 'Customer reference is not valid. Please confirm that the Customer Reference has been entered correctly.' };
    }

    // console.log('Validating: Valid');
    return { valid: true, error: '' };
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!customerRef.valid) {
      setFormError('Please provide customer reference');
      return;
    }
    setSubmitting(true);
    // alert(`Submitting Customer Reference ${customerRef}`);

    try {
      const parsed: any = await axios.post(
        `https://${process.env.REACT_APP_SUPPORT_HOST}/${process.env.REACT_APP_SUPPORT_PATH}`,
        {
          invoice: {
            customerReference: customerRef.value,
            merchantId: "11000"
          }
        },
        {});

      // console.log(parsed);

      // check if error, set and return;
      if (parsed.error) {
        setError(parsed.error);
        setSubmitting(false);
        return;
      }

      setResponse(parsed.data);
    } catch (err) {
      // console.error(err);
      setError({ message: 'Request failed' })
    }

    setSubmitting(false);

  };

  return (
    <div className={'clientPage'}>
      {
        response.status ?
          <Response { ...response } />
          :
          <div>
            <h1>Get my PIN</h1>
            <p>Enter the Customer Reference specified on your till slip below to retrieve your pin.</p>

            <div className={'myForm'}>
              <form onSubmit={handleSubmit} className='d-flex'>
                <div className='form-group flex-fill'>
                  <label className={'label'} htmlFor="customerReference">Customer reference:</label>
                  <InputMask
                    mask="********-****-****-****-************"
                    onChange={e => handleChange(e)}
                    onBlur={() => handleBlur()}
                    onFocus={() => handleFocus()}
                  >
                    {() => <input
                      id="customerReference"
                      type="text"
                      className="form-control"
                      placeholder="00000000-0000-0000-0000-000000000000"
                      name='customerReference'
                      />}
                  </InputMask>

                  { customerRef.dirty && !customerRef.valid ? <div className={'error'}>{customerRef.error}</div> : null}

                  {
                    error.message ? <div className={'error'}>
                      We could not retrieve a pin for the provided Customer Reference. Please confirm that the Customer Reference has been entered correctly.
                    </div> : null
                  }

                  {
                    formError ? <div className={'error'}>
                      { formError }
                    </div> : null
                  }

                  <br/>

                  <input
                    className='btn btn-primary mr-1 mb-2 flex-fill btn-lg btn-block'
                    type="submit"
                    value="Get my PIN"
                    disabled={submitting}/>
                </div>
              </form>
            </div>

            <div className={'faq'}>
              <h3 className={'title'}>Where can I find my Customer Reference?</h3>
              <p>You can find your Customer Reference towards the bottom of the receipt provided by Spencer.</p>
              <div className={'tillSlip-container'}>
                <img src={tillSlip} className={'tillSlip'} alt="till slip" />
              </div>
            </div>

            <br/>

            <HavingTroubleComponent />


          </div>
      }

    </div>
  )
};

export default ClientPage;
